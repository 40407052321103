// @ts-nocheck
import {Link} from 'react-router-dom';
import './Footer.css';
import {Email, Phone, Sms, Place, Instagram, VideoLibrary} from '@material-ui/icons';

const navigation = [
    {name: 'Home', href: '/'},
    {name: 'Vehicles', href: '/vehicles'},
    {name: 'Financing', href: '/financing'},
]
const social = [
    {name: 'Insta', icon: Instagram, href: 'https://www.instagram.com/pratapmotors/'},
    {name: 'Phone', icon: Phone, href: 'tel:+14168364648'},
    {
        name: 'Email',
        icon: Email,
        href: 'mailto:pratapmotorsltd@gmail.com?subject=Contact%20Pratap%20Motors&body=Tell-us'
    },
    {name: 'Sms', icon: Sms, href: 'sms:+14168364648'},
    {name: 'Location', icon: Place, href: 'https://g.page/pratapmotorsltd?share'},
    {name: 'TikTok', icon: VideoLibrary, href: 'https://www.tiktok.com/@pratapmotorsltd?lang=en'},
]

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-row">
                {navigation.map((item) => (
                    <Link key={item.name} to={item.href}
                          className="text-sm md:text-lg text-gray-500 hover:text-gray-900">
                        {item.name}
                    </Link>
                ))}
            </div>
            <div className="footer-row">
                {social.map(
                    item =>
                        <a key={item.name} href={item.href}>
                            <item.icon className="h-6 w-6 text-yellow-600 hover:text-yellow-900" aria-hidden="true"/>
                        </a>
                )}
            </div>

            <div className="footer-row">
                <div className="text-sm md:text-lg text-gray-400">
                     &copy; {new Date().getFullYear()} Pratap Motors, All rights reserved.
                </div>
            </div>

        </div>
    )
}

export default Footer;