// @ts-nocheck
import React, {useState} from 'react';
import {Button, Grid, Paper,} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {firestore} from "../../firebase/firebaseUtils";
import CoreCarDetailsForm from "./CoreCarDetailsForm";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiFormControl-root": {
            width: "50%",
            margin: theme.spacing(1),
        },
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(4),
    },
    margin: {
        margin: theme.spacing(1),
        marginLeft: 0,
    },
    left: {
        textAlign: "left",
    },
    none: {
        display: "none",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const initialState = {
    title: "", // Car Description for sale
    body: "", // bodyType
    vinNumber: "", // VIN
    manufacturer: "", // Make
    model: "", // Model Name
    registrationYear: "", // Make Year
    odometer: 0,
    exteriorColour: "",
    interiorColour: "",
    transmissionType: "",
    gears: "",
    drive: "",
    price: 0,
    fuelType: "",
    engine: "",
    regularPrice: 0,
    contactForPrice: false,
    publish: false,
    sold: false,
    description: "",
    images: [],
};

const CarDetails = ({setActivateNext}) => {
    const [values, setValues] = useState(initialState);

    const [doc, setDocument] = useState({});
    const [edit, setEdit] = useState(true);

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };
    const handleDateChange = (value) => {
        setValues({...values, registrationYear: "" + value.$y});
    };
    const handleSwitch = (event) => {
        setValues({...values, [event.target.name]: event.target.checked});
    };
    const handleDescription = (event) => {
        setValues({...values, description: event()});
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (doc && doc.id) {
            doc.ref
                .update({
                    ...values,
                })
                .then(() => {
                    setEdit(false);
                })
                .catch((error) => {
                    alert("Could not add your data, try again", error);
                });

            return;
        }
        const ref = firestore.collection("cars").doc();
        ref
            .set({
                ...values,
            })
            .then(() => {
                //console.log("Document written with ID: ", ref.id);
                setDocument({
                    ref,
                    id: ref.id,
                });
                setEdit(false);
            })
            .catch((error) => {
                alert("Could not add your data, try again", error);
            });
    };

    const handleEdit = (e) => {
        setEdit(true);
    };

    const handleNext = (e) => {
        setActivateNext({activateNext: true, doc});
    };
    const classes = useStyles();
    return (
        <Paper className={classes.pageContent}>
            <form className={classes.root} onSubmit={handleSubmit}>
                <Grid container>
                    <CoreCarDetailsForm handleChange={handleChange} handleDateChange={handleDateChange}
                                        handleSwitch={handleSwitch} handleDescription={handleDescription}
                                        values={values}/>
                    <div style={{marginTop: "24px"}}>
                        <Button variant="contained" onClick={handleEdit} color="primary">
                            Edit your document
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={!edit}
                            color="primary"
                            style={{marginLeft: "8px"}}
                        >
                            Submit
                        </Button>

                        <Button
                            style={{marginLeft: "8px"}}
                            variant="contained"
                            onClick={handleNext}
                            color="secondary"
                        >
                            Upload Images
                        </Button>
                    </div>
                </Grid>
            </form>
        </Paper>
    );
};

export default CarDetails;
