// @ts-nocheck

import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Calculator() {
    let query = useQuery();
    const [calculator, setValues] = useState({
        price: query.get('price') || 10000,
        tradeIn: 0,
        salesTax: 13,
        downPayment: 0,
        term: 60,
        interestRate: 5,
        loanAmount: 0,
        emi: 0,
    });

    const updateEmiCalculation = ({price, tradeIn, salesTax, downPayment, term, interestRate, loanAmount, emi}) => {
        const amountBefore = parseFloat(price - tradeIn - downPayment);
        loanAmount = parseFloat(amountBefore + (amountBefore * salesTax) / 100);
        const rate = parseFloat(interestRate / 12 / 100);
        /*
        * E = P * r * ( (1+r)^n / (1+r)^n - 1 )
        * https://emicalculator.net/
        * */
        emi = parseFloat(loanAmount * rate * ( Math.pow((1 + rate), term) / (Math.pow((1 + rate), term) - 1) )).toFixed(2);


        return {
            price,
            tradeIn,
            salesTax,
            downPayment,
            term,
            interestRate,
            loanAmount,
            emi
        }
    }

    const updateValues = (event) => {
        const updatedState = updateEmiCalculation({...calculator, [event.target.name]: event.target.value});
        setValues(updatedState);
    }

    return (
        <div className="max-w-7xl mx-auto px-4">
            <div className="col-span-12 sm:col-span-5">
                <label htmlFor="price"
                       className="block text-sm font-medium text-gray-700">
                    Price
                </label>
                <input
                    type="number"
                    name="price"
                    id="price"
                    value={calculator.price}
                    onChange={updateValues}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
            </div>

            <div className="col-span-12 sm:col-span-5">
                <label htmlFor="tradeIn"
                       className="block text-sm font-medium text-gray-700">
                    Trade In price
                </label>
                <input
                    type="number"
                    name="tradeIn"
                    id="tradeIn"
                    value={calculator.tradeIn}
                    onChange={updateValues}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
            </div>

            <div className="col-span-12 sm:col-span-5">
                <label htmlFor="salesTax"
                       className="block text-sm font-medium text-gray-700">
                    Sales Tax
                </label>
                <input
                    type="number"
                    name="salesTax"
                    id="salesTax"
                    value={calculator.salesTax}
                    onChange={updateValues}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
            </div>

            <div className="col-span-12 sm:col-span-5">
                <label htmlFor="downPayment"
                       className="block text-sm font-medium text-gray-700">
                    Down Payment
                </label>
                <input
                    type="number"
                    name="downPayment"
                    id="downPayment"
                    value={calculator.downPayment}
                    onChange={updateValues}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
            </div>

            <div className="col-span-12 sm:col-span-5">
                <label htmlFor="term"
                       className="block text-sm font-medium text-gray-700">
                    Months
                </label>
                <input
                    type="number"
                    name="term"
                    id="term"
                    value={calculator.term}
                    onChange={updateValues}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
            </div>

            <div className="col-span-12 sm:col-span-5">
                <label htmlFor="interestRate"
                       className="block text-sm font-medium text-gray-700">
                    Interest Rate
                </label>
                <input
                    type="number"
                    name="interestRate"
                    id="interestRate"
                    value={calculator.interestRate}
                    onChange={updateValues}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
            </div>

            <div className="col-span-12 sm:col-span-5">
                <label htmlFor="loanAmount"
                       className="block text-sm font-medium text-gray-700">
                    Loan Amount
                </label>
                <input
                    type="number"
                    name="loanAmount"
                    id="loanAmount"
                    value={calculator.loanAmount}
                    onChange={updateValues}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
            </div>

            <div className="col-span-12 sm:col-span-5">
                <label htmlFor="emi"
                       className="block text-sm font-medium text-gray-700">
                    Monthly Rate
                </label>
                <input
                    type="number"
                    name="emi"
                    id="emi"
                    value={calculator.emi}
                    onChange={updateValues}
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
            </div>
        </div>
    );
}

export default Calculator;
