// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {Button, Grid, Paper,} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CoreCarDetailsForm from "./CoreCarDetailsForm";
import {useParams, useHistory} from 'react-router-dom';
import {firestore} from '../../firebase/firebaseUtils';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiFormControl-root": {
            width: "50%",
            margin: theme.spacing(1),
        },
    },
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(4),
    },
    margin: {
        margin: theme.spacing(1),
        marginLeft: 0,
    },
    left: {
        textAlign: "left",
    },
    none: {
        display: "none",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


const initialState = {
    title: "", // Car Description for sale
    body: "", // bodyType
    vinNumber: "", // VIN
    manufacturer: "", // Make
    model: "", // Model Name
    registrationYear: "", // Make Year
    odometer: 0,
    exteriorColour: "",
    interiorColour: "",
    transmissionType: "",
    gears: "",
    drive: "",
    price: 0,
    fuelType: "",
    engine: "",
    regularPrice: 0,
    contactForPrice: false,
    description: "",
    images: [],
};


const EditCarDetails = () => {
    const [doc, setDoc] = useState();
    const [values, setValues] = useState(initialState);
    const {id} = useParams();
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            const ref = await firestore.collection("cars").doc(id);
            const doc = await ref.get();
            setValues(doc.data());
            setDoc(doc);
        }
        fetchData();
    }, [id])

    const classes = useStyles();

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };
    const handleDateChange = (value) => {
        setValues({...values, registrationYear: "" + value.$y});
    };
    const handleSwitch = (event) => {
        setValues({...values, [event.target.name]: event.target.checked});
    };
    const handleDescription = (event) => {
        setValues({...values, description: event()});
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (doc && doc.id) {
            try {
                doc.ref.update({...values})
            }catch (e) {
                console.log(e);
            }
            history.push('/cars');
        }

    };
    if (!doc || !doc.id) {
        return (<div>Document Loading or Not Found</div>);
    }
    return (
        <Paper className={classes.pageContent}>
            <form className={classes.root} onSubmit={handleSubmit}>
                <Grid container>
                    <CoreCarDetailsForm handleChange={handleChange} handleDateChange={handleDateChange}
                                        handleSwitch={handleSwitch} handleDescription={handleDescription}
                                        values={values}/>
                    <div>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            style={{marginLeft: "8px"}}
                        >
                            Submit
                        </Button>
                    </div>
                </Grid>
            </form>
        </Paper>
    );
};

export default EditCarDetails;
