import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CarDetails from "../CarDetails/CarDetails";
import FileUploader from "../FileUploader/FileUploader";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
);

function getSteps() {
  return ["Add New Car", "Upload Car Images"];
}

// @ts-ignore
function getStepContent(step: number, handleActivateNext, store) {
  switch (step) {
    case 0:
      return <CarDetails setActivateNext={handleActivateNext} />;
    case 1:
      return <FileUploader setActivateNext={handleActivateNext} doc={store} />;
    default:
      return "Unknown step";
  }
}

export default function FormStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [activateNext, setActivateNext] = React.useState(false);
  const [doc, setDoc] = React.useState({});
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setActivateNext(false);
  };

  // @ts-ignore
  const handleActivateNext = ({ activateNext, doc }) => {
    setActivateNext((next) => (next = activateNext));
    setDoc(doc);
  };

  const handleBack = () => {
    setActivateNext(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setActivateNext(false);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
                <Grid>{getStepContent(index, handleActivateNext, doc)}</Grid>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!activateNext}
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Add New Car
          </Button>
        </Paper>
      )}
    </div>
  );
}
