// @ts-nocheck
import React from 'react';
import Slider from 'react-slick';
import "./SliderComp.css";

function SliderComp({children, settings}) {
    return (
        <div className="container rounded-lg bg-gray-600">
            <Slider {...settings}>
                {children}
            </Slider>
        </div>
    );
}

export default SliderComp;