// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import firebase, {storage} from "../../firebase/firebaseUtils";
import {Button} from "@material-ui/core";

const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
    padding: 20
};

const thumb = {
    position: "relative",
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box"
};

const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden"
};

const img = {
    display: "block",
    width: "auto",
    height: "100%"
};

const thumbButton = {
    position: "absolute",
    right: 10,
    bottom: 10,
    background: "rgba(0,0,0,.8)",
    color: "#fff",
    border: 0,
    borderRadius: ".325em",
    cursor: "pointer"
};

function FileUploader({setActivateNext, doc}) {
    const [files, setFiles] = useState([]);
    const [uploaded, setUploaded] = useState([]);
    const [upload, setUpload] = useState(0);

    const {getRootProps, getInputProps} = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            const pr = [...files, ...acceptedFiles];
            const uniq = Array.from(new Set(pr.map((item: any) => item.name)))
            const final = uniq.map(name => {
                return pr.find(p => p.name === name)
            })
            setFiles(final);
        }
    });


    const handleSetImageOnDocument = (url, metaData) => {
        doc.ref.update({
            images: firebase.firestore.FieldValue.arrayUnion({
                url,
                metaData,
            }),
        });
        setUploaded([...uploaded, metaData.title]);
    };

    const handleFileUpload = (file) => {
        const storageRef = storage.ref();
        const metadata = {
            customMetadata: {
                size: `${file.size}`,
                title: `${file.name}`
            },
        };

        // const formData = new FormData();
        // const photos = document.querySelector('input[type="file"][multiple]');
        //
        // for (let i = 0; i < photos.files.length; i++) {
        //     formData.append('photos', photos.files[i]);
        // }
        //
        // fetch('http://localhost:3000/upload', {
        //     method: 'POST',
        //     body: formData,
        // })
        //     .then(response => console.log(response))
        //     .then(result => {
        //         console.log('Success:', result);
        //     })
        //     .catch(error => {
        //         console.error('Error:', error);
        //     });


        // https://github.com/firebase/quickstart-js/issues/6
        const uploadTask = storageRef
            .child("images/" + Date.now() + file.name)
            .put(file, metadata);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
                setUpload(progress);
            },
            (error) => {
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    handleSetImageOnDocument(downloadURL, metadata.customMetadata);
                });
            }
        );
    }

    const allDone = () => {
        setActivateNext({activateNext: true, doc});
    };


    const thumbs = files.map((file, index) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={URL.createObjectURL(file)} style={img} alt=""/>
            </div>
            <button
                style={thumbButton}
                disabled={uploaded.includes(file.name)}
                onClick={() => {
                    handleFileUpload(file)
                }}>
                {uploaded.includes(file.name) ? 'Uploaded' : 'Upload'}
            </button>
        </div>
    ));

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    return (
        <section className="container">
            <div {...getRootProps({className: "dropzone"})}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside style={thumbsContainer}>{thumbs}</aside>
            <h2>Upload Status
                : {upload} :: {files.length === uploaded.length && files.length > 0 ? 'All Uploaded' : 'Upload now'}</h2>
            <Button variant={"outlined"} onClick={allDone}>
                Done Uploading
            </Button>
        </section>
    );
}

export default FileUploader;
