// @ts-nocheck
import React from 'react';
import {
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateUtil from "@date-io/dayjs";
import Editor from "rich-markdown-editor";

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
        marginLeft: 0,
    },
    left: {
        textAlign: "left",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const CoreCarDetailsForm = ({handleChange, handleDateChange, handleSwitch, handleDescription, values}) => {
    const classes = useStyles();
    const {
        title,
        body,
        vinNumber,
        manufacturer,
        model,
        odometer,
        registrationYear,
        exteriorColour,
        interiorColour,
        transmissionType,
        gears,
        drive,
        engine,
        fuelType,
        price,
        regularPrice,
        contactForPrice,
        publish,
        sold,
        description,
    } = values;
    return (
        <MuiPickersUtilsProvider utils={DateUtil}>
            <Grid item>
                <TextField
                    variant="outlined"
                    label="Title"
                    onChange={handleChange("title")}
                    value={title}
                    required
                />
                <FormControl className={classes.formControl}>
                    <InputLabel id="body-type">Body Type</InputLabel>
                    <Select
                        required
                        labelId="body-type"
                        id="body-select"
                        value={body}
                        onChange={handleChange("body")}
                    >
                        <MenuItem value={"Hatchback"}>
                            Hatchback
                        </MenuItem>
                        <MenuItem value={"Coupe"}>
                            Coupe
                        </MenuItem>
                        <MenuItem value={"Convertible"}>
                            Convertible
                        </MenuItem>
                        <MenuItem value={"Sedan"}>
                            Sedan
                        </MenuItem>
                        <MenuItem value={"Station Wagon"}>
                            Station Wagon
                        </MenuItem>
                        <MenuItem value={"Crossover"}>
                            Crossover
                        </MenuItem>
                        <MenuItem value={"SUV"}>
                            SUV
                        </MenuItem>
                        <MenuItem value={"Minivan"}>
                            Minivan
                        </MenuItem>
                        <MenuItem value={"Pickup"}>
                            Pickup
                        </MenuItem>
                        <MenuItem value={"Pickup - Regular Cab"}>
                            Pickup - Regular Cab
                        </MenuItem>
                        <MenuItem value={"Pickup - Extended Cab"}>
                            Pickup - Extended Cab
                        </MenuItem>
                        <MenuItem value={"Pickup - Double Cab"}>
                            Pickup - Double Cab
                        </MenuItem>
                        <MenuItem value={"Pickup - Crew Cab"}>
                            Pickup - Crew Cab
                        </MenuItem>
                        <MenuItem value={"Cargo Van"}>
                            Cargo Van
                        </MenuItem>
                        <MenuItem value={"Box Truck"}>
                            Box Truck
                        </MenuItem>
                        <MenuItem value={"Chassis Cab"}>
                            Chassis Cab
                        </MenuItem>
                        <MenuItem value={"Other"}>
                            Other
                        </MenuItem>
                        <MenuItem value={"Passenger Van"}>
                            Passenger Van
                        </MenuItem>
                        <MenuItem value={"Crew Van"}>
                            Crew Van
                        </MenuItem>
                        <MenuItem value={"Bus"}>
                            Bus
                        </MenuItem>

                    </Select>
                </FormControl>
                <TextField
                    variant="outlined"
                    label="Vin Number"
                    onChange={handleChange("vinNumber")}
                    value={vinNumber}
                />
                <TextField
                    variant="outlined"
                    required
                    label="Odometer"
                    onChange={handleChange("odometer")}
                    value={odometer}
                />
                <TextField
                    variant="outlined"
                    label="Make"
                    onChange={handleChange("manufacturer")}
                    value={manufacturer}
                    required
                />
                <TextField
                    variant="outlined"
                    label="Model Name"
                    onChange={handleChange("model")}
                    value={model}
                    required
                />
                <DatePicker
                    views={["year"]}
                    label="Registration Year"
                    value={new Date(registrationYear || "2021")}
                    onChange={handleDateChange}
                    required
                    maxDate={new Date()}
                    minDate={new Date("1970")}
                />

                <FormControl className={classes.formControl}>
                    <InputLabel id="body-type">Exterior Colour</InputLabel>
                    <Select
                        required
                        labelId="ex-colour-type"
                        id="ex-colour-select"
                        value={exteriorColour}
                        onChange={handleChange("exteriorColour")}
                    >
                        <MenuItem value={"Black"}>Black</MenuItem>
                        <MenuItem value={"White"}>White</MenuItem>
                        <MenuItem value={"Grey"}>Grey</MenuItem>
                        <MenuItem value={"Blue"}>Blue</MenuItem>
                        <MenuItem value={"Red"}>Red</MenuItem>
                        <MenuItem value={"Yellow"}>Yellow</MenuItem>
                        <MenuItem value={"Green"}>Green</MenuItem>
                        <MenuItem value={"Orange"}>Orange</MenuItem>
                        <MenuItem value={"Beige"}>Beige</MenuItem>
                        <MenuItem value={"Brown"}>Brown</MenuItem>
                        <MenuItem value={"Silver"}>Silver</MenuItem>
                        <MenuItem value={"Gold"}>Gold</MenuItem>
                        <MenuItem value={"Purple"}>Purple</MenuItem>
                        <MenuItem value={"Charcoal"}>Charcoal</MenuItem>
                        <MenuItem value={"Bronze"}>Bronze</MenuItem>
                        <MenuItem value={"Burgandy"}>Burgandy</MenuItem>
                        <MenuItem value={"Tin"}>Tin</MenuItem>
                        <MenuItem value={"Tan"}>Tan</MenuItem>
                        <MenuItem value={"Taupe"}>Taupe</MenuItem>
                        <MenuItem value={"Cream"}>Cream</MenuItem>
                        <MenuItem value={"Light blue"}>Light blue</MenuItem>
                        <MenuItem value={"Dark blue"}>Dark blue</MenuItem>
                        <MenuItem value={"Dark green"}>Dark green</MenuItem>
                        <MenuItem value={"Light green"}>Light green</MenuItem>
                        <MenuItem value={"Dark grey"}>Dark grey</MenuItem>

                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel id="body-type">Interior Colour</InputLabel>
                    <Select
                        required
                        labelId="in-colour-type"
                        id="in-colour-select"
                        value={interiorColour}
                        onChange={handleChange("interiorColour")}
                    >
                        <MenuItem value={"Black"}>Black</MenuItem>
                        <MenuItem value={"White"}>White</MenuItem>
                        <MenuItem value={"Grey"}>Grey</MenuItem>
                        <MenuItem value={"Blue"}>Blue</MenuItem>
                        <MenuItem value={"Red"}>Red</MenuItem>
                        <MenuItem value={"Yellow"}>Yellow</MenuItem>
                        <MenuItem value={"Green"}>Green</MenuItem>
                        <MenuItem value={"Orange"}>Orange</MenuItem>
                        <MenuItem value={"Beige"}>Beige</MenuItem>
                        <MenuItem value={"Brown"}>Brown</MenuItem>
                        <MenuItem value={"Silver"}>Silver</MenuItem>
                        <MenuItem value={"Gold"}>Gold</MenuItem>
                        <MenuItem value={"Purple"}>Purple</MenuItem>
                        <MenuItem value={"Charcoal"}>Charcoal</MenuItem>
                        <MenuItem value={"Bronze"}>Bronze</MenuItem>
                        <MenuItem value={"Burgandy"}>Burgandy</MenuItem>
                        <MenuItem value={"Tin"}>Tin</MenuItem>
                        <MenuItem value={"Tan"}>Tan</MenuItem>
                        <MenuItem value={"Taupe"}>Taupe</MenuItem>
                        <MenuItem value={"Cream"}>Cream</MenuItem>
                        <MenuItem value={"Light blue"}>Light blue</MenuItem>
                        <MenuItem value={"Dark blue"}>Dark blue</MenuItem>
                        <MenuItem value={"Dark green"}>Dark green</MenuItem>
                        <MenuItem value={"Light green"}>Light green</MenuItem>
                        <MenuItem value={"Dark grey"}>Dark grey</MenuItem>
                    </Select>
                </FormControl>


                <FormControl className={classes.formControl}>
                    <InputLabel id="transmission-type">Transmission Type</InputLabel>
                    <Select
                        required
                        labelId="transmission-type"
                        id="transmission-select"
                        value={transmissionType}
                        onChange={handleChange("transmissionType")}
                    >
                        <MenuItem value={"Manual"}>Manual</MenuItem>
                        <MenuItem value={"Automatic"}>Automatic</MenuItem>
                        <MenuItem value={"Automatic w/ Manual Mode"}>Automatic w/ Manual Mode</MenuItem>
                        <MenuItem value={"CVT"}>CVT</MenuItem>
                        <MenuItem value={"Sequential"}>Sequential</MenuItem>
                        <MenuItem value={"Semi Automatic"}>Semi Automatic</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    variant="outlined"
                    label="Gears"
                    onChange={handleChange("gears")}
                    value={gears}
                />
                <FormControl className={classes.formControl}>
                    <InputLabel id="transmission-type">Drive Train</InputLabel>
                    <Select
                        labelId="drive-type"
                        id="drive-select"
                        value={drive}
                        onChange={handleChange("drive")}
                    >
                        <MenuItem value="Front Wheel Drive">Front Wheel Drive</MenuItem>
                        <MenuItem value="Rear Wheel Drive">Rear Wheel Drive</MenuItem>
                        <MenuItem value="All Wheel Drive">All Wheel Drive</MenuItem>
                        <MenuItem value="4x4">4x4</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel id="fuel-type">Fuel Type</InputLabel>
                    <Select
                        labelId="fuel-type"
                        id="fuel-select"
                        value={fuelType}
                        onChange={handleChange("fuelType")}
                    >
                        <MenuItem value="Gasoline">Gasoline</MenuItem>
                        <MenuItem value="Unleaded">Unleaded</MenuItem>
                        <MenuItem value="Premium Unleaded">Premium Unleaded</MenuItem>
                        <MenuItem value="Diesel">Diesel</MenuItem>
                        <MenuItem value="Natural Gas">Natural Gas</MenuItem>
                        <MenuItem value="Electric">Electric</MenuItem>
                        <MenuItem value="Hybrid">Hybrid</MenuItem>
                        <MenuItem value="Ethanol">Ethanol</MenuItem>
                        <MenuItem value="Leaded">Leaded</MenuItem>
                        <MenuItem value="FlexFuel">FlexFuel</MenuItem>
                        <MenuItem value="Plug In Hybrid">Plug In Hybrid</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    variant="outlined"
                    label="Engine"
                    onChange={handleChange("engine")}
                    value={engine}
                />
                <FormControl fullWidth className={classes.margin}>
                    <InputLabel htmlFor="standard-adornment-amount">
                        Price
                    </InputLabel>
                    <Input
                        id="standard-adornment-amount"
                        onChange={handleChange("price")}
                        variant="secondary"
                        value={price}
                        required
                        startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                        }
                    />
                </FormControl>

                <FormControl fullWidth className={classes.margin}>
                    <InputLabel htmlFor="regular-price">
                        Regular Price
                    </InputLabel>
                    <Input
                        id="regular-price"
                        onChange={handleChange("regularPrice")}
                        variant="secondary"
                        value={regularPrice}
                        startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                        }
                    />
                </FormControl>

                <FormGroup style={{alignItems: "baseline"}}>
                    <FormControlLabel
                        control={<Switch
                            checked={contactForPrice}
                            onChange={handleSwitch}
                            name="contactForPrice"
                            inputProps={{'aria-label': 'contact for price checkbox'}}
                        />}
                        label="Contact for price"
                        labelPlacement="start"
                    />
                </FormGroup>

                <FormGroup style={{alignItems: "baseline"}}>
                    <FormControlLabel
                        control={<Switch
                            checked={publish}
                            onChange={handleSwitch}
                            name="publish"
                            inputProps={{'aria-label': 'publish listing'}}
                        />}
                        label="Publish Status"
                        labelPlacement="start"
                    />
                </FormGroup>

                <FormGroup style={{alignItems: "baseline"}}>
                    <FormControlLabel
                        control={<Switch
                            checked={sold}
                            onChange={handleSwitch}
                            name="sold"
                            inputProps={{'aria-label': 'listing is sold'}}
                        />}
                        label="Sold"
                        labelPlacement="start"
                    />
                </FormGroup>

                <FormControl fullWidth className={classes.margin}>
                    <FormLabel className={`${classes.left} ${classes.margin}`}>
                        Description
                    </FormLabel>
                    <Editor defaultValue={description} onChange={handleDescription}/>
                </FormControl>
            </Grid>
        </MuiPickersUtilsProvider>
    );
};

export default CoreCarDetailsForm;
