//@ts-nocheck
import {Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography} from "@material-ui/core";
import {useEffect, useState} from "react";
import {firestore} from "../../firebase/firebaseUtils";
import {Link} from "react-router-dom";

const ListCars = () => {
    const [cars, setCars] = useState([]);

    useEffect(() => {
        try {
            getAllData().then(r => console.log(r));
        } catch (e) {
            alert(e.message);
        }
    }, []);

    const getAllData = async () => {
        const ref = firestore.collection("cars");
        const snapshot = await ref.get();
        snapshot.forEach((doc) => {
            setCars((cars) => [
                ...cars,
                {
                    ref: doc,
                    id: doc.id,
                    ...doc.data(),
                },
            ]);
        });
        return snapshot;
    };

    const deleteCarListing = async (car) => {
        try {
            await car.ref.ref.delete();
        } catch (e) {
            console.error('Something went wrong ', e.message);
        }
    }

    return (
        <>
            <Grid style={{padding: "24px"}} justify={"center"} spacing={2} container>
                {cars &&
                cars.length > 0 &&
                cars.map((car) =>
                    <Grid item xs={3} key={car.id}>
                        <Card>
                            <CardActionArea>
                                {
                                    car && car.images.length > 0 &&
                                    <CardMedia
                                        component="img"
                                        alt={car.description}
                                        height="300"
                                        image={car.default ? car.default.url : car.images[0].url}
                                        title={car.default ? car.default.title : car.images[0].title}
                                    />
                                }
                                <CardContent>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                    >
                                        Title : {car.title}
                                    </Typography>

                                    <Typography
                                        variant="caption"
                                        color="textPrimary"
                                        component="p"
                                    >
                                        Manufacturer : {car.manufacturer}
                                    </Typography>

                                    <Typography
                                        variant="caption"
                                        color="textPrimary"
                                        component="p"
                                    >
                                        Price : {car.price}
                                    </Typography>

                                    <Typography
                                        variant="caption"
                                        color="textPrimary"
                                        component="p"
                                    >
                                        Available Images : {car.images.length}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button
                                    size="small"
                                    color="primary"
                                    onClick={deleteCarListing.bind(this, car)}
                                >
                                    Delete
                                </Button>
                                <Button size="small" color="primary">
                                    <Link to={`/edit/${car.id}`}>Edit</Link>
                                </Button>
                                <Button size="small" color="primary">
                                    <Link to={`/images/${car.id}`}>Edit Images</Link>
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>)}
            </Grid>
        </>
    );
};
export default ListCars;
