// @ts-nocheck
import {
    ChevronRightRounded,
    Info
} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";

const numberFormatter = new Intl.NumberFormat();

const Vehicle = ({car}) => {

    const onOffModal = (e, car) => {
        console.log('modal', e, car);
    }

    return (
        <div className="font-sans">
            <div className="max-w-6xl mx-auto">
                <div className="flex items-center justify-center ">
                    <div className="w-full md:w-3/4  py-6 px-3">
                        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
                            <div className="bg-cover bg-center h-64 md:h-80 p-4"
                                 style={{backgroundImage: `url(${car.default.url})`}}>
                                <Button onClick={onOffModal.bind(this, car)}><Info
                                    className="flex justify-end"/></Button>
                            </div>
                            <div className="p-4">
                                <p className="uppercase tracking-wide text-sm font-bold text-gray-700">{car.title}</p>
                                <p className="text-3xl text-gray-900">$ {numberFormatter.format(car.price)}</p>
                                <p className="text-gray-700"><span
                                    className="font-bold">Year:</span> {car.registrationYear}</p>
                                <p className="text-gray-700"><span className="font-bold">Body Style:</span> {car.body}
                                </p>
                                <p className="text-gray-700"><span
                                    className="font-bold">Exterior Colour :</span> {car.exteriorColour}</p>
                                <p className="text-gray-700"><span
                                    className="font-bold">Pictures :</span> {car.images.length}</p>
                            </div>
                            <div className="flex p-4 border-t border-gray-300 text-gray-700">
                                <div className="flex-1 inline-flex items-center">

                                    <p><span className="text-gray-900 font-bold">Kilometres: </span> {car.odometer}</p>
                                </div>
                                <div className="flex-1 inline-flex items-center">

                                    <p><span className="text-gray-900 font-bold">Engine: </span> {car.engine}</p>
                                </div>
                            </div>
                            <div className="px-4 pt-3 pb-4 border-t border-green-300 bg-gray-100">
                                <div className="flex items-center flex-col gap-6 sm:flex-row pt-2">
                                    <Button variant="contained" color="primary" size="large" className="w-full"
                                            endIcon={<ChevronRightRounded/>}>
                                        <Link to={`/vehicle/${car.id}`}>View More Details</Link>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Vehicle;