//@ts-nocheck
import React from 'react';
import TransitionalModal from "../Modal/TranitionalModal";
import ContactUs from "./ContactUs";

function ModalContactUs({open, handleClose}) {
    return (
        <TransitionalModal children={<ContactUs handleClose={handleClose}/>} open={open} handleClose={handleClose}/>
    );
}


export default ModalContactUs;