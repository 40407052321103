// @ts-nocheck
import {Fzf} from "fzf";

let fzf = {};
let target = "";

const initSearch = (data, selector) => {
    if(data.length < 1 || selector.length < 1) {
        return;
    }
    target = selector;
    fzf = new Fzf(data, {
        selector: (item) => item[target],
    });
    return fzf;
}


const queryResult = (query: string) => {
    if(fzf.length < 1) {
        console.log('data not initialized');
        return;
    }
    const entries = fzf.find(query);
    console.log(entries.map(entry => entry.item[target]));
    return entries;
}

export {initSearch, queryResult};

