import {useState} from "react";

export const useHandleOpen = (initialValue = false) => {
    const [open, setOpen] = useState(initialValue);

    const handleOpen = () => {
        console.log('open called', open);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return [open, handleOpen, handleClose];
};