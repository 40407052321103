// @ts-nocheck
import React from 'react';
import {TextareaAutosize} from "@material-ui/core";
import "./ContactUs.css";

function ContactUs({handleClose}) {
    const handleContactRequest = (event) => {
        event.preventDefault();
        console.log(JSON.stringify(Object.fromEntries(new FormData(event.currentTarget).entries())));
        handleClose();
    }

    return (
        <div>
            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Contact Us Form</h3>
                            <p className="mt-1 text-sm text-gray-600">We will contact you soon.</p>
                        </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form onSubmit={handleContactRequest}>
                            <div className="shadow overflow-hidden sm:rounded-md">
                                <div className="px-4 py-5 bg-white sm:p-6">
                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="full-name"
                                                   className="block text-sm font-medium text-gray-700">
                                                Full name *
                                            </label>
                                            <input
                                                type="text"
                                                name="full-name"
                                                id="full-name"
                                                required
                                                autoComplete="given-name"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-4">
                                            <label htmlFor="email-address"
                                                   className="block text-sm font-medium text-gray-700">
                                                Email address *
                                            </label>
                                            <input
                                                type="text"
                                                name="email-address"
                                                id="email-address"
                                                required
                                                autoComplete="email"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-4">
                                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                                Phone number
                                            </label>
                                            <input
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                autoComplete="phone"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="country"
                                                   className="block text-sm font-medium text-gray-700">
                                                Reach me by
                                            </label>
                                            <select
                                                id="country"
                                                name="country"
                                                autoComplete="country"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            >
                                                <option>Phone</option>
                                                <option>Email</option>
                                                <option>Text</option>
                                            </select>
                                        </div>

                                        <div className="col-span-6">
                                            <label htmlFor="message"
                                                   className="block text-sm font-medium text-gray-700">
                                                Message
                                            </label>
                                            <TextareaAutosize aria-label="your message" name="message" id="message"
                                                              autoComplete="message"
                                                              rowsMin={3}
                                                              placeholder="Enter your message here.
                                                               We will contact you soon."
                                                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                    <button
                                        type="submit"
                                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Done
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;
