//@ts-nocheck
import React, {useEffect, useState} from "react";
import {Button, Card, CardActionArea, CardActions, CardMedia} from "@material-ui/core";
import {useParams, useHistory} from "react-router-dom";
import {firestore} from "../../firebase/firebaseUtils";
import "./CarImages.css";
import FileUploader from "../FileUploader/FileUploader";

const ImagesComp = ({url, title, handleDelete, handleMakeDefault, isDefault}) => (
    <Card style={{width: "24vw", height: "300px", margin: "2vw 0"}}>
        <CardActionArea>
            <CardMedia
                component="img"
                alt={title}
                height="220"
                style={{
                    maxHeight: 250,
                }}
                image={url}
                title={title}
            />
        </CardActionArea>
        <CardActions>
            <Button
                size="small"
                color="primary"
                onClick={handleDelete.bind(this, url, title)}
            >
                Delete
            </Button>
            <Button size="small" color="primary" onClick={handleMakeDefault.bind(this, url, title)}>
                Make Default
            </Button>
            <h3>{isDefault ? 'Default' : ''}</h3>
        </CardActions>
    </Card>
);

const EditCarImages = () => {
    const [doc, setDoc] = useState();
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const {id} = useParams();
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            const ref = await firestore.collection("cars").doc(id);
            const doc = await ref.get();
            setImages(doc.data().images);
            setDoc(doc);
            setLoading(false);
        }
        fetchData();
    }, [id])

    const handleMakeDefault = (url, title) => {
        if (doc && doc.id) {
            doc.ref.update({
                default: {
                    url,
                    title
                }
            })
        }
    };

    const handleDelete = (url, title) => {
        setImages(images.filter(image => image.url !== url))
    }

    const updateDeleted = () => {
        if (doc && doc.id) {
            try {
                doc.ref.update({
                    images,
                });
            } catch (e) {
                alert(e.message);
            }
            history.push('/cars');
        }
    }

    return (
        <div className="images">
            {
                loading ? <div>Loading</div> :
                    <>
                        <FileUploader style={{flex: 1}} setActivateNext={() => console.log('Done Uploading')}
                                      doc={doc}/>
                        <Button id="updateDeletedImages" style={{width: "200px", height: "60px", margin: "40px"}}
                                variant="outlined"
                                onClick={updateDeleted}>Update Changes to Images</Button>
                        {
                            images &&
                            images.length > 0 &&
                            images.map((image, index) => <ImagesComp key={index + image.url} url={image.url}
                                                                     title={image.metaData.title}
                                                                     isDefault={doc.data().default ? image.url === doc.data().default.url : false}
                                                                     handleDelete={handleDelete}
                                                                     handleMakeDefault={handleMakeDefault}/>)
                        }
                    </>
            }

        </div>
    )
}

export default EditCarImages;
