// @ts-nocheck
import "./PageHeader.css";
import {Call, Timer, Map, Instagram, Textsms} from "@material-ui/icons";
import {Popover, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/solid";
import React, {Fragment} from "react";
import {MenuIcon, XIcon} from '@heroicons/react/outline'
import {Link} from "react-router-dom";
import logo from '../../assets/icon.jpeg';
import ModalContactUs from "../ContactUs/ModalContactUs";
import {useHandleOpen} from "../../states/openCloseState";

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const navigation = [
    {name: 'Home', href: '/'},
    {name: 'Vehicles', href: '/vehicles'},
    {name: 'Financing', href: '/financing'},
]

const timings = [
    {
        name: "Monday - Friday",
        timing: "09:00 AM - 7:00 PM"
    },

    {
        name: "Saturday",
        timing: "09:00 AM - 6:00 PM"
    },

    {
        name: "Sunday",
        timing: "10:00 AM - 4:00 PM"
    }
]


const PageHeader = () => {
    const [open, handleOpen, handleClose] = useHandleOpen();
    return (
        <div className="home">
            <div className="header">
                <ModalContactUs handleClose={handleClose} open={open} />
                <div className="flex-col md:flex-row flex justify-between items-center">
                    <div className="group justify-start">
                        <Call className="text-grey-600 group-hover:text-green-900"/>
                        <a href="tel:+14168364648"
                           className="px-1 text-grey-500 group-hover:text-green-900">(416)-836-4648</a>
                    </div>
                    <div className="group inline-flex">
                        <Map className="text-grey-600 group-hover:text-green-900"/>
                        <a href="https://www.google.ca/maps/dir//PRATAP+MOTORS,+100+Stafford+Dr,+Brampton,+ON+L6W+1L4,+Canada"
                           rel="noreferrer" target="_blank" className="text-grey-600 group-hover:text-green-900">100
                            Stafford Dr, Brampton, ON L6W 1L4, Canada</a>
                    </div>
                    <div className="group justify-end inline-flex">
                        <Popover className="relative">
                            {({open}) => (
                                <>
                                    <Popover.Button
                                        className={classNames(
                                            open ? 'text-gray-900' : 'text-gray-500',
                                            'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                        )}
                                    >
                                        <Timer className="text-grey-600 group-hover:text-green-900"/>
                                        <span>Our Opening Times</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                open ? 'text-gray-900' : 'text-gray-400',
                                                'ml-2 h-5 w-5 group-hover:text-gray-500'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel
                                            static
                                            className="absolute z-10 -ml-4 mt-3 transform px-2 w-60 sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                                        >
                                            <div
                                                className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                <div
                                                    className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                                    {timings.map(({name, timing}) => (
                                                        <div key={name} className="ml-4">
                                                            <p className="text-base font-medium text-gray-900">{name}</p>
                                                            <p className="mt-1 text-sm text-gray-500">{timing}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )}
                        </Popover>
                        <div className="group px-4">
                            <a href="https://www.instagram.com/pratapmotors/" rel="noreferrer">
                                <Instagram />
                            </a>
                            <a href="sms:+14168364648">
                                <Textsms />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="corona p-4">*/}
            {/*    <div className="container mx-auto lg:text-center">COVID UPDATE - We Are Open by Appointment Only! Call*/}
            {/*        (416) 836 4648 or Email pratapmotorsltd@gmail.com to Book*/}
            {/*    </div>*/}
            {/*</div>*/}

            <Popover>
                {({open}) => (
                    <>
                        <div className="max-w-7xl mx-auto relative py-4 sm:px-6">
                            <nav
                                className="relative flex items-center justify-between sm:h-10"
                                aria-label="Global"
                            >
                                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                                    <div className="flex items-center justify-between w-full md:w-auto">
                                        <a href="/">
                                            <span className="sr-only">Pratap Motors</span>
                                            <img
                                                className="h-8 w-auto sm:h-10"
                                                src={logo}
                                                alt="pratap motors logo"
                                            />
                                        </a>
                                        <div className="-mr-2 flex items-center md:hidden">
                                            <Popover.Button
                                                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">Open main menu</span>
                                                <MenuIcon className="h-6 w-6" aria-hidden="true"/>
                                            </Popover.Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                                    {navigation.map((item) => (
                                        <Link key={item.name} to={item.href}
                                              className="text-lg text-gray-500 hover:text-gray-900">
                                            {item.name}
                                        </Link>
                                    ))}
                                    <button onClick={handleOpen}
                                          className="cursor-pointer text-xl text-indigo-600 hover:text-indigo-500">
                                        Contact Us
                                    </button>
                                </div>
                            </nav>
                        </div>

                        <Transition
                            show={open}
                            as={Fragment}
                            enter="duration-150 ease-out"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="duration-100 ease-in"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Popover.Panel
                                focus
                                static
                                className="absolute z-20 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                            >
                                <div
                                    className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                                    <div className="px-5 pt-4 flex items-center justify-between">
                                        <img
                                            className="h-8 w-auto"
                                            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                                            alt=""
                                        />
                                        <div className="-mr-2">
                                            <Popover.Button
                                                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">Close main menu</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true"/>
                                            </Popover.Button>
                                        </div>
                                    </div>
                                    <div className="px-2 pt-2 pb-3 space-y-1">
                                        {navigation.map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.href}
                                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                                            >
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                    <button
                                        onClick={handleOpen}
                                        className="cursor-pointer block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100"
                                    >
                                        Contact Us
                                    </button>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>

        </div>
    );
}

export default PageHeader;



