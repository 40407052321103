import React from "react";
import "./App.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeProvider} from "@material-ui/core/styles";
import "fontsource-roboto";
import theme from "./theme";
import FormStepper from "./Components/FormStepper/FormStepper";
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import ListCars from "./Components/ListCars/ListCars";
import Header from "./Components/Header/Header";
import Login from "./Components/Login/Login";
import {AuthProvider} from "./contexts/AuthContext";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import EditCarDetails from "./Components/CarDetails/EditCarDetails";
import EditCarImages from "./Components/CarDetails/EditCarImages";
import HomePage from "./Components/HomePage";
import Financing from "./Components/Financing/Financing";
import Footer from "./Components/Footer/Footer";
import Vehicles from "./Components/Vehicles/Vehicles";
import VehicleDetail from "./Components/Vehicles/VehicleDetail";
import Calculator from "./Components/Calculator/Calculator";

class App extends React.Component<any, any> {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                    <Router>
                        <AuthProvider>
                            <Header/>
                            <Switch>
                                <PrivateRoute exact path="/admin" component={FormStepper}/>
                                <PrivateRoute exact path="/cars" component={ListCars}/>
                                <PrivateRoute exact path="/edit/:id" component={EditCarDetails}/>
                                <PrivateRoute exact path="/images/:id" component={EditCarImages}/>
                                <Route exact path="/login" component={Login}/>
                                <Route exact path="/" component={HomePage}/>
                                <Route exact path="/financing" component={Financing} />
                                <Route exact path="/emi" component={Calculator} />
                                <Route exact path="/vehicles" component={Vehicles} />
                                <Route exact path="/vehicle/:id" component={VehicleDetail} />
                                <Route path="*" render={(props) => <Redirect to="/" {...props} push />} />
                            </Switch>
                            <Footer />
                        </AuthProvider>
                    </Router>
            </ThemeProvider>
        );
    }
}

export default App;
