// @ts-nocheck
import {firestore} from "../../firebase/firebaseUtils";
import {useEffect, useState} from "react";
import Vehicle from "./Vehicle";
import {initSearch, queryResult} from "../../services/SearchService";

const Vehicles = () => {
        const [cars, setCars] = useState([]);
        const [carsData, setCarsData] = useState([]);

        useEffect(() => {
            try {
                getAllData().then(data => {
                    setCarsData(data);
                    initSearch(data, 'title');
                });
            } catch (e) {
                alert(e.message);
            }
        }, []);

        const updateSearch = (event) => {
            const {value} = event.target;
            const result = queryResult(value);
            console.log(carsData);
            if(result.length === 0) {
                return setCars(carsData);
            }
            return setCars(result.map(entry => entry.item));
        }

        const getAllData = async () => {
            const ref = firestore.collection("cars");
            const snapshot = await ref.get();
            let carsData = [];
            snapshot.forEach((doc) => {
                carsData = [
                    ...carsData,
                    {
                        ref: doc,
                        id: doc.id,
                        ...doc.data(),
                    },
                ];
            });
            setCars(carsData);
            return carsData;
        };

        return (<div className="antialiased bg-gray-200 ">
            <div className="flex py-4 justify-center">
                <input name="search" className="w-3/4 md:w-1/2" type="search" onChange={updateSearch} autoComplete="off" maxLength="10" placeholder="Enter car name to search"/>
            </div>
            {cars &&
            cars.length > 0 &&
            cars.map((car) => <Vehicle key={car.title} car={car}/>)}
        </div>);
    }
;

export default Vehicles;