// @ts-nocheck
import "./Financing.css";
import {TextareaAutosize} from "@material-ui/core";

export default function Financing() {
    return (
        <div className="centered-comp">
            <div className="py-4 px-2 bg-gray-50 rounded-lg">
                <h2 className="font-extrabold text-4xl">We help you with financing.</h2>
                <p className="mt-2 text-gray-400">100% APPROVAL. STUDENTS (International Students), Bankruptcy, Bad
                    Credit, Cash Income, Disability, New To Country, Self Employed, 9 Sin#, Divorced. We Are Here To
                    Help. CONDITIONS APPLY</p>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200"/>
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
                            <p className="mt-1 text-sm text-gray-600">Use a permanent address where you can receive
                                mail.</p>
                        </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form action="#" method="POST">
                            <div className="shadow overflow-hidden sm:rounded-md">
                                <div className="px-4 py-5 bg-white sm:p-6">

                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6 grid grid-cols-12 gap-4">
                                            <div className="col-span-6 sm:col-span-2">
                                                <label htmlFor="salutation"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Salutation
                                                </label>
                                                <select
                                                    id="salutation"
                                                    name="salutation"
                                                    required
                                                    autoComplete="salutation"
                                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                >
                                                    <option>Mr.</option>
                                                    <option>Mrs.</option>
                                                    <option>Miss</option>
                                                </select>
                                            </div>
                                            <div className="col-span-12 sm:col-span-5">
                                                <label htmlFor="first-name"
                                                       className="block text-sm font-medium text-gray-700">
                                                    First name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="first-name"
                                                    id="first-name"
                                                    required
                                                    autoComplete="given-name"
                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>

                                            <div className="col-span-12 sm:col-span-5">
                                                <label htmlFor="last-name"
                                                       className="block text-sm font-medium text-gray-700">
                                                    Last name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="last-name"
                                                    required
                                                    id="last-name"
                                                    autoComplete="family-name"
                                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-span-6 sm:col-span-2">
                                            <label htmlFor="phone"
                                                   className="block text-sm font-medium text-gray-700">
                                                Mobile no.
                                            </label>
                                            <input
                                                type="tel"
                                                name="phone"
                                                id="phone"
                                                required
                                                autoComplete="phone"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-4">
                                            <label htmlFor="email-address"
                                                   className="block text-sm font-medium text-gray-700">
                                                Email address
                                            </label>
                                            <input
                                                type="email"
                                                name="email-address"
                                                id="email-address"
                                                autoComplete="email"
                                                required
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-2">
                                            <label htmlFor="marital"
                                                   className="block text-sm font-medium text-gray-700">
                                                Marital Status
                                            </label>
                                            <select
                                                id="marital"
                                                name="marital"
                                                required
                                                autoComplete="marital"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            >
                                                <option value="Single">Single</option>
                                                <option value="Married">Married</option>
                                                <option value="Divorced">Divorced</option>
                                                <option value="Other">Other</option>

                                            </select>
                                        </div>

                                        <div className="col-span-6 hidden sm:block" aria-hidden="true">
                                            <div className="py-5">
                                                <div className="border-t border-gray-200"/>
                                            </div>
                                        </div>

                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="province"
                                                   className="block text-sm font-medium text-gray-700">
                                                Province
                                            </label>
                                            <select
                                                id="province"
                                                name="province"
                                                autoComplete="province"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            >
                                                <option value="Ontario">Ontario</option>
                                                <option value="Alberta">Alberta</option>
                                                <option value="British Columbia">British Columbia</option>
                                                <option value="Manitoba">Manitoba</option>
                                                <option value="New Brunswick">New Brunswick</option>
                                                <option value="Newfoundland and Labrador">Newfoundland and
                                                    Labrador
                                                </option>
                                                <option value="Nova Scotia">Nova Scotia</option>
                                                <option value="Northwest Territories">Northwest Territories</option>
                                                <option value="Nunavut">Nunavut</option>
                                                <option value="Quebec">Quebec</option>
                                                <option value="Saskatchewan">Saskatchewan</option>
                                                <option value="Yukon">Yukon</option>
                                            </select>
                                        </div>

                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="postal-code"
                                                   className="block text-sm font-medium text-gray-700">
                                                ZIP / Postal
                                            </label>
                                            <input
                                                type="text"
                                                name="postal-code"
                                                id="postal-code"
                                                autoComplete="postal-code"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="street-address"
                                                   className="block text-sm font-medium text-gray-700">
                                                Street address
                                            </label>
                                            <input
                                                type="text"
                                                name="street-address"
                                                id="street-address"
                                                autoComplete="street-address"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                                City
                                            </label>
                                            <input
                                                type="text"
                                                name="city"
                                                id="city"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>


                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="home" className="block text-sm font-medium text-gray-700">
                                                Home
                                            </label>
                                            <select
                                                id="home"
                                                name="home"
                                                autoComplete="home"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            >
                                                <option value="Own With Mortgage">Own With Mortgage</option>
                                                <option value="Own Free &amp; Clear">Own Free &amp; Clear</option>
                                                <option value="Lives with Parents">Lives with Parents</option>
                                            </select>
                                        </div>


                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="year" className="block text-sm font-medium text-gray-700">
                                                Duration
                                            </label>
                                            <input
                                                type="number"
                                                name="year"
                                                id="year"
                                                placeholder="Years"
                                                className="mt-1 focus:ring-indigo-500 inline-block focus:border-indigo-500 block w-1/2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            <input
                                                type="number"
                                                name="month"
                                                id="month"
                                                placeholder="Months"
                                                className="mt-1 focus:ring-indigo-500 inline-block focus:border-indigo-500 block w-1/2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                    <button
                                        type="submit"
                                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200"/>
                </div>
            </div>


            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Employment</h3>
                            <p className="mt-1 text-sm text-gray-600">About your employment and income.</p>
                        </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form action="#" method="POST">
                            <div className="shadow overflow-hidden sm:rounded-md">
                                <div className="px-4 py-5 bg-white sm:p-6">

                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="employment"
                                                   className="block text-sm font-medium text-gray-700">
                                                Employment Type
                                            </label>
                                            <select
                                                id="employment"
                                                name="employment"
                                                required
                                                autoComplete="employment"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            >
                                                <option value="Full Time">Full Time</option>
                                                <option value="Part Time">Part Time</option>
                                                <option value="Contract">Contract</option>
                                                <option value="Self Employed">Self Employed</option>
                                            </select>
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="social-insurance-number"
                                                   className="block text-sm font-medium text-gray-700">
                                                Social Insurance Number
                                            </label>
                                            <input
                                                type="text"
                                                name="social-insurance-number"
                                                id="social-insurance-number"
                                                autoComplete="social-insurance-number"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="occupation"
                                                   className="block text-sm font-medium text-gray-700">
                                                Occupation
                                            </label>
                                            <input
                                                type="text"
                                                name="occupation"
                                                required
                                                id="last-name"
                                                autoComplete="occupation"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="employer"
                                                   className="block text-sm font-medium text-gray-700">
                                                Present Employer
                                            </label>
                                            <input
                                                type="text"
                                                name="employer"
                                                id="employer"
                                                required
                                                autoComplete="employer"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="employer-address"
                                                   className="block text-sm font-medium text-gray-700">
                                                Employer Address
                                            </label>
                                            <input
                                                type="text"
                                                name="employer-address"
                                                id="employer-address"
                                                autoComplete="employer-address"
                                                required
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="employer-phone"
                                                   className="block text-sm font-medium text-gray-700">
                                                Employer Phone no.
                                            </label>
                                            <input
                                                type="tel"
                                                name="employer-phone"
                                                id="employer-phone"
                                                autoComplete="employer-phone"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="employer-province"
                                                   className="block text-sm font-medium text-gray-700">
                                                Province
                                            </label>
                                            <select
                                                id="employer-province"
                                                name="employer-province"
                                                required
                                                autoComplete="employer-province"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            >
                                                <option value="Ontario">Ontario</option>
                                                <option value="Alberta">Alberta</option>
                                                <option value="British Columbia">British Columbia</option>
                                                <option value="Manitoba">Manitoba</option>
                                                <option value="New Brunswick">New Brunswick</option>
                                                <option value="Newfoundland and Labrador">Newfoundland and
                                                    Labrador
                                                </option>
                                                <option value="Nova Scotia">Nova Scotia</option>
                                                <option value="Northwest Territories">Northwest Territories</option>
                                                <option value="Nunavut">Nunavut</option>
                                                <option value="Quebec">Quebec</option>
                                                <option value="Saskatchewan">Saskatchewan</option>
                                                <option value="Yukon">Yukon</option>
                                            </select>
                                        </div>

                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="postal-code"
                                                   className="block text-sm font-medium text-gray-700">
                                                ZIP / Postal
                                            </label>
                                            <input
                                                type="text"
                                                name="postal-code"
                                                id="postal-code"
                                                required
                                                autoComplete="postal-code"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                                City
                                            </label>
                                            <input
                                                type="text"
                                                name="city"
                                                id="city"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>


                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="employment-years"
                                                   className="block text-sm font-medium text-gray-700">
                                                Duration
                                            </label>
                                            <input
                                                type="number"
                                                name="employment-years"
                                                id="employment-years"
                                                placeholder="Years"
                                                className="mt-1 focus:ring-indigo-500 inline-block focus:border-indigo-500 block w-1/2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            <input
                                                type="number"
                                                name="employment-months"
                                                id="employment-months"
                                                placeholder="Months"
                                                className="mt-1 focus:ring-indigo-500 inline-block focus:border-indigo-500 block w-1/2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>


                                        <div className="col-span-6 hidden sm:block" aria-hidden="true">
                                            <div className="py-5">
                                                <div className="border-t border-gray-200"/>
                                            </div>
                                        </div>


                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="previous-employer"
                                                   className="block text-sm font-medium text-gray-700">
                                                Previous Employer.
                                            </label>
                                            <input
                                                type="text"
                                                name="previous-employer"
                                                id="previous-employer"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>


                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="previous-employment-years"
                                                   className="block text-sm font-medium text-gray-700">
                                                Duration
                                            </label>
                                            <input
                                                type="number"
                                                name="previous-employment-years"
                                                id="previous-employment-years"
                                                placeholder="Years"
                                                className="mt-1 focus:ring-indigo-500 inline-block focus:border-indigo-500 block w-1/2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                            <input
                                                type="number"
                                                name="previous-employment-months"
                                                id="previous-employment-months"
                                                placeholder="Months"
                                                className="mt-1 focus:ring-indigo-500 inline-block focus:border-indigo-500 block w-1/2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>


                                    </div>
                                </div>
                                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                    <button
                                        type="submit"
                                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200"/>
                </div>
            </div>


            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Income and Credit Details</h3>
                            <p className="mt-1 text-sm text-gray-600">About your income and credit history.</p>
                        </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form action="#" method="POST">
                            <div className="shadow overflow-hidden sm:rounded-md">
                                <div className="px-4 py-5 bg-white sm:p-6">

                                    <div className="grid grid-cols-6 gap-6">

                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="income" className="block text-sm font-medium text-gray-700">
                                                Gross Income.
                                            </label>
                                            <input
                                                type="number"
                                                name="income"
                                                id="income"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="income-per"
                                                   className="block text-sm font-medium text-gray-700">
                                                Per
                                            </label>
                                            <select
                                                id="income-per"
                                                name="income-per"
                                                required
                                                autoComplete="income-per"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            >
                                                <option value="Annual">Annual</option>
                                                <option value="Monthly">Monthly</option>
                                                <option value="Weekly">Weekly</option>
                                                <option value="Daily">Daily</option>
                                            </select>
                                        </div>

                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="other-income-type"
                                                   className="block text-sm font-medium text-gray-700">
                                                Other Income Type.
                                            </label>
                                            <input
                                                type="text"
                                                name="other-income-type"
                                                id="other-income-type"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="other-income"
                                                   className="block text-sm font-medium text-gray-700">
                                                Other Income.
                                            </label>
                                            <input
                                                type="number"
                                                name="other-income"
                                                id="other-income"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="other-income-per"
                                                   className="block text-sm font-medium text-gray-700">
                                                Per
                                            </label>
                                            <select
                                                id="other-income-per"
                                                name="other-income-per"
                                                autoComplete="-per"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            >
                                                <option value="Annual">Annual</option>
                                                <option value="Monthly">Monthly</option>
                                                <option value="Weekly">Weekly</option>
                                                <option value="Daily">Daily</option>
                                            </select>
                                        </div>


                                        <div className="col-span-6">
                                            <label htmlFor="other-income-type"
                                                   className="block text-sm font-medium text-gray-700">
                                                Other Income Description.
                                            </label>
                                            <TextareaAutosize
                                                rowsMin="3"
                                                name="other-income-type"
                                                id="other-income-type"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>


                                        <div className="col-span-6 hidden sm:block" aria-hidden="true">
                                            <div className="py-5">
                                                <div className="border-t border-gray-200"/>
                                            </div>
                                        </div>


                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="bankruptcy"
                                                   className="block text-sm font-medium text-gray-700">
                                                Have you declared bankruptcy within the past 7 years?
                                            </label>
                                            <select
                                                id="bankruptcy"
                                                name="bankruptcy"
                                                autoComplete="-per"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            >
                                                <option value="">---</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>

                                            </select>
                                        </div>

                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="repossessed"
                                                   className="block text-sm font-medium text-gray-700">
                                                Have you ever had a vehicle repossessed?
                                            </label>
                                            <select
                                                id="repossessed"
                                                name="repossessed"
                                                autoComplete="-per"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            >
                                                <option value="">---</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>

                                            </select>
                                        </div>

                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="co-financing"
                                                   className="block text-sm font-medium text-gray-700">
                                                If necessary, do you have a co-signer for your financing?
                                            </label>
                                            <select
                                                id="co-financing"
                                                name="co-financing"
                                                autoComplete="-per"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            >
                                                <option value="">---</option>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>

                                            </select>
                                        </div>

                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="credit-rating"
                                                   className="block text-sm font-medium text-gray-700">
                                                Please Rate Your Credit.
                                            </label>
                                            <select
                                                id="credit-rating"
                                                name="credit-rating"
                                                autoComplete="-per"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            >
                                                <option value="Good Credit">Good Credit</option>
                                                <option value="Average">Average</option>
                                                <option value="No Credit">No Credit</option>
                                                <option value="Bad Credit">Bad Credit</option>

                                            </select>
                                        </div>


                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label htmlFor="trade-in"
                                                   className="block text-sm font-medium text-gray-700">
                                                Have Trade-In Vehicle?
                                            </label>
                                            <select
                                                id="trade-in"
                                                name="trade-in"
                                                autoComplete="-per"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            >
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </select>
                                        </div>


                                    </div>
                                </div>
                                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                    <button
                                        type="submit"
                                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200"/>
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Submit All</h3>
                            <p className="mt-1 text-sm text-gray-600">If all information provided is correct. Please
                                submit.</p>
                        </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form action="#" method="POST">

                            <div className="shadow overflow-hidden sm:rounded-md">
                                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                    <fieldset>
                                        <legend className="text-base font-medium text-gray-900">By Email</legend>
                                        <div className="mt-4 space-y-4">
                                            <div className="flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input
                                                        id="certify"
                                                        name="certify"
                                                        type="checkbox"
                                                        required
                                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="certify" className="font-medium text-gray-700">
                                                        Certify Correct info
                                                    </label>
                                                    <p className="text-gray-500"> I Certify That Each Of The Statements
                                                        Made And Answers Given In This Credit Application Are True And
                                                        Correct And Is Made For The Purpose Of Inducing The Financing Of
                                                        The Purchase Of A Motor Vehicle. I Authorize The Obtaining Of A
                                                        Consumer Report To Be Used In Evaluating This Application And
                                                        The Obtaining And Exchanging Of Credit Information From And With
                                                        Other Creditors And Consumer Reporting Agencies.</p>
                                                </div>
                                            </div>
                                            <div className="flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input
                                                        id="gdpr"
                                                        name="gdpr"
                                                        type="checkbox"
                                                        required
                                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="gdpr" className="font-medium text-gray-700">
                                                        Privacy
                                                    </label>
                                                    <p className="text-gray-500">I agree to privacy policy</p>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                    <button
                                        type="submit"
                                        className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
