// @ts-nocheck
import React, {useRef, useState} from "react";
import TextField from "@material-ui/core/TextField";
import "./Login.css";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useAuth} from "../../contexts/AuthContext";
import {useHistory} from "react-router-dom";

const Login = () => {
    const emailRef = useRef()
    const passwordRef = useRef()
    const {login, currentUser, logout} = useAuth();
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError("")
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            history.push("/admin")
        } catch (e) {
            setError(`Failed to log in ${e.message}`)
        }

        setLoading(false)
    }

    const {root, label} = makeStyles({
        root: {
            background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
            borderRadius: 3,
            border: 0,
            color: "white",
            height: 48,
            marginTop: 20,
            padding: "0 30px",
            boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        },
        label: {
            textTransform: "capitalize",
        },
    })();

    return (
        <div className="Login">
            {error && <h3 className="mm">{error}</h3>}
            <form className="LoginForm" autoComplete="off" onSubmit={handleSubmit}>
                <TextField
                    autoFocus
                    margin={"normal"}
                    id="email"
                    name="email"
                    type="email"
                    inputRef={emailRef}
                    label="Email"
                    required
                />
                <TextField
                    margin={"normal"}
                    id="password"
                    name="password"
                    type="password"
                    inputRef={passwordRef}
                    label="Password"
                    required
                />
                <Button
                    disabled={loading}
                    classes={{
                        root,
                        label,
                    }}
                    variant={"contained"}
                    color={"primary"}
                    type="submit"
                >
                    Sign In
                </Button>
            </form>

            <h3 className="mm">Already logged In ? {currentUser && currentUser.email} <Button
                disabled={loading}
                variant={"contained"}
                color={"primary"}
                onClick={logout}
            >
                Log Out
            </Button></h3>
        </div>
    );
};

export default Login;
