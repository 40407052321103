// @ts-nocheck
import "./Header.css";
import {AppBar, Button, IconButton, makeStyles, Toolbar,} from "@material-ui/core";
import {MenuOpenRounded} from "@material-ui/icons";
import theme from "../../theme";
import {NavLink} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";
import PageHeader from "./PageHeader";

const styles = makeStyles({
    menuButton: {
        margin: theme.spacing(2),
    },
    title: {
        margin: "8px",
    },
});

const Header = () => {
    const classes = styles();
    const {currentUser, logout} = useAuth();
    return (
        <>
            {currentUser && currentUser.email ? <AppBar position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                    >
                        <NavLink exact activeStyle={{
                            fontWeight: "bold",
                            color: "lightgreen"
                        }} to="/"><MenuOpenRounded/></NavLink>
                    </IconButton>
                    <Button color="inherit"><NavLink exact activeStyle={{
                        fontWeight: "bold",
                        color: "lightgreen"
                    }} to="/admin">Admin</NavLink></Button>
                    <Button color="inherit"><NavLink exact activeStyle={{
                        fontWeight: "bold",
                        color: "lightgreen"
                    }} to="/cars">Cars</NavLink></Button>
                    {
                        currentUser && currentUser.email ? <Button color="inherit" onClick={logout}>Log out</Button> :
                            <Button color="inherit"><NavLink exact activeStyle={{
                                fontWeight: "bold",
                                color: "lightgreen"
                            }} to="/login">Login</NavLink></Button>
                    }
                </Toolbar>
            </AppBar>
                : <PageHeader />
            }
        </>

    );
};

export default Header;
