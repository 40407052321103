// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {firestore} from "../../firebase/firebaseUtils";
import {LinearProgress} from "@material-ui/core";
import Editor from "rich-markdown-editor";
import Button from "@material-ui/core/Button";
import {ChevronRightRounded, Print} from "@material-ui/icons";
import SliderComp from "../Slider/SliderComp";
import ModalContactUs from "../ContactUs/ModalContactUs";
import {useHandleOpen} from "../../states/openCloseState";

const numberFormatter = new Intl.NumberFormat();

function VehicleDetail(props) {
    const {id} = useParams();
    const [car, setCar] = useState({});
    const [loading, setLoading] = useState(true);
    const [open, handleOpen, handleClose] = useHandleOpen();

    useEffect(() => {
        const fetchData = async () => {
            const ref = await firestore.collection("cars").doc(id);
            const doc = await ref.get();
            return doc.data();
        }
        fetchData().then(car => {
                setCar(car);
                setLoading(false);
            }
        );
    }, [id])

    const createCarImages = (images) => images.map((item,index) => <div>
        <img key={index} className="w-full h-56 sm:h-96" src={item.url} alt={item.title || "car image"}/>
    </div>);

    return (
        <div>
            {loading ? <LinearProgress/> :
                <div className="max-w-7xl mx-auto">
                    {car && car.images.length > 0 &&
                    <div className="">
                        <ModalContactUs handleClose={handleClose} open={open} />
                        <SliderComp children={createCarImages(car.images)} settings={{dots:true, autoplay: true}}/>
                        <div className="flex flex-col sm:flex-row">
                            <div className="sm:w-3/4 shadow overflow-hidden sm:rounded-lg">
                                <div className="px-4 py-5 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">{car.title}</h3>
                                </div>
                                <div className="border-t border-gray-200">
                                    <dl className="bg-gray-50 even-child:bg-white">
                                        <div
                                            className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Year</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{car.registrationYear}</dd>
                                        </div>
                                        <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Price</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">$ {numberFormatter.format(car.price)}</dd>
                                        </div>

                                        <div
                                            className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Engine</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{car.engine}</dd>
                                        </div>

                                        <div
                                            className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Kilometer</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{car.odometer}</dd>
                                        </div>


                                        {car.body && <div
                                            className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Body</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{car.body}</dd>
                                        </div> }
                                        {car.vinNumber && <div
                                            className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Vin Number</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{car.vinNumber}</dd>
                                        </div> }

                                        {car.manufacturer && <div
                                            className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Manufacturer</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{car.manufacturer}</dd>
                                        </div> }

                                        {car.model && <div
                                            className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Model</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{car.model}</dd>
                                        </div> }


                                        {car.exteriorColour && <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Exterior Colour
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{car.exteriorColour}</dd>
                                        </div>}

                                        {car.interiorColour && <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Interior Colour
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{car.interiorColour}</dd>
                                        </div>}
                                        {car.transmissionType && <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Transmission Type
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{car.transmissionType}</dd>
                                        </div>}
                                        {car.fuelType && <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Fuel Type
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{car.transmissionType}</dd>
                                        </div>}
                                        {car.drive && <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Drive
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{car.drive}</dd>
                                        </div>}
                                        {car.gear && <div className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Gear
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{car.gear}</dd>
                                        </div>}

                                        <div
                                            className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Description</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <Editor defaultValue={car.description} onChange={() => {
                                                }} readOnly/>
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                            <div
                                className="flex flex-col sm:w-1/4 shadow overflow-hidden sm:rounded-lg gap-4">
                                <div className="flex h-8 mt-4 justify-center">
                                    <Button variant="outlined" size="small" endIcon={<Print />} color="secondary">Print</Button>
                                </div>
                                <Button variant="contained" color="primary" size="large" className="w-full"
                                        endIcon={<ChevronRightRounded/>} onClick={handleOpen}>Contact Us
                                </Button>
                                <Button variant="contained" color="primary" size="large" className="w-full"
                                        endIcon={<ChevronRightRounded/>}>
                                    <Link to={`/emi?price=${car.price}`}>Finance Calculator</Link>
                                </Button>
                                <Button variant="contained" color="primary" size="large" className="w-full"
                                        endIcon={<ChevronRightRounded/>}>
                                    <Link to={`/financing`}>Apply for Financing</Link>
                                </Button>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            }
        </div>
    );
};

export default VehicleDetail;