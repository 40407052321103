import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const config = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId:  process.env.REACT_APP_PROJECTID,
    storageBucket:  process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId:  process.env.REACT_APP_MESSAGINGSENDERID,
    appId:  process.env.REACT_APP_APPID,
    measurementId:  process.env.REACT_APP_MEASUREMENTID,
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({prompt: 'select_account'});

export const signInWithGoogle = () => auth.signInWithPopup(provider);
export default firebase;

